<!--
    Список всіх активних і завершених тестувань
    Фільтри та пошук: Фільтрація за статусом, датою, відповідальним, типом тестування тощо.
    Кнопка "Додати нове тестування": Для створення нових тестових сесій.
-->

<template>

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />
    
    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - :createdform - параметр-прапорець, що дозволяє чи забороняє створення нових записів у таблиці
        - - йде перевірка на права доступу 2000 (режим бога)
        - @create - подія, яка спрацьовує при створенні нового завдання на тестування
        - @open - подія, яка спрацьовує при відкритті картки завдання на тестування
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB" 
        :columns="columns" 
        :rows="rows" 
        :pages="objPages"
        :showpag="true"
        :createdform="this.perms[2000]" 
        @create="showCreatedBox = true"
        @open="open" 
        @getdata="getdata"
        @changelimit="changelimit"
    />

    <!-- 
        Вікно створення картки завдання
        - для відкриття застосовується перевірка значення showCreatedBox
        - @close - подія, яка спрацьовує при закритті картки
    -->
    <createBox 
        v-if="showCreatedBox"
        @close="showCreatedBox = false"
    />

    <viewBox 
        v-if="showViewBox.show == true"
        @close="showViewBox.show = false"
        :objCard="this.showViewBox.obj"
    />

</template>

<script>
import axios from "axios";
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import createBox from './created_testing/index' // картка створення завдання qa
import viewBox from './view_testing/index'; // картка завдання qa
import { storeS } from '@/store' // глобальне сховище даних

export default {
    components: {
        PageHeader,
        tablecustom,
        createBox,
        viewBox
    },
    data() {
        return {
            title: this.$t('Testing'), // Заголовок
            showCreatedBox: false, // прапорець відображення вікна створення
            showViewBox: {
                show: false, // прапорець відображення вікна картки
                obj: '' // дані
            },
            dataParams: {
                status: true,
                page: "testing"
            },
            objParams:{
                page: '1',
                pagelimit: '10',
                search: '',
                priority: '',
                status: ''
            },
            columns: [
                {
                    name: "ID",
                    text: "id",
                    align: "left",
                    status: true
                },
                {
                    name: "Середовище тестування",
                    text: "template",
                    align: "left",
                    status: true
                },
                {
                    name: "Назва",
                    text: "title",
                    align: "left",
                    status: true
                },
                {
                    name: "Пріоритет",
                    text: "priority",
                    align: "left",
                    status: true
                },
                {
                    name: "Додав",
                    text: "userName",
                    align: "left",
                    status: true
                },
                {
                    name: "Статус",
                    text: "status",
                    align: "left",
                    status: true
                },
            ],
            rows: [
                
            ]
        }
    },
    created(){
        this.getdata()
    },
    methods: {
        getdata(){
            axios
            .get('http://localhost:3001/testingList')
            .then(result => {
                this.objPages = result.data;
                this.rows = result.data.items;  
                console.log("rows", this.rows);
            });
        },
        open(e){
            // Відкриття картки

            this.showViewBox.obj = e
            this.showViewBox.show = true


        }
    },
    computed: {
        perms(){
            /*
                Повертає дані прав доступу користувача з глобального стору.
            */
            return storeS.perms
        },
        checks() {
            /*
                Повертає дані з налаштувань юзера з глобального стору.
            */
            return storeS.checks
        },
        user(){
            /*
                Повертає дані за користувача з глобального стору.
            */
            return storeS.userbase
        }
    },
}

</script>